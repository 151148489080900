import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: white;
  width: 100%;
`;

const Wrapper = styled.div`
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: white;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: black;
  margin: 10px;

  @media (max-width: 768px) {
    font-size: 1em;
    margin: 5px;
  }
`;

const StyledLink = styled(Link)`
  font-size: 1.5em;
  text-align: center;
  color: black;
  margin: 5px;
  padding: 15px;
  text-decoration: none;
  opacity: 0.6;
  background-color: white;
  transition: opacity 0.5s ease-in-out 0s;
  transition: background-color 0.5s ease-in-out 0s;
  transition: border-width 0.5s ease-in-out 0s;
  border-radius: 30px;
  border: ${props => props.isPagedDisplayed && '2px solid lightgrey'};

  &:hover {
    opacity: 1;
    background-color: ${props => (props.nobackground ? 'white' : 'lightgrey')};
  }

  @media (max-width: 768px) {
    font-size: 0.8em;
    margin: 5px;
    padding: 0;
  }

  @media (max-width: 400px) {
    font-size: 0.5em;
  }
`;

function Header() {
  const [currentPage, setCurrentPage] = useState(window.location.pathname);

  return (
    <StyledHeader>
      <Wrapper>
        <StyledLink to="/" nobackground onClick={() => setCurrentPage('/')}>
          <Title>Salomé Sitbon | Comédienne</Title>
        </StyledLink>
      </Wrapper>
      <Wrapper>
        <StyledLink
          to="/mon-histoire"
          isPagedDisplayed={currentPage === '/mon-histoire'}
          onClick={() => setCurrentPage('/mon-histoire')}
        >
          Qui suis-je ?
        </StyledLink>
        <StyledLink
          to="/mon-expérience"
          isPagedDisplayed={currentPage === '/mon-expérience'}
          onClick={() => setCurrentPage('/mon-expérience')}
        >
          Mes Expériences
        </StyledLink>
        <StyledLink
          to="/me-contacter"
          isPagedDisplayed={currentPage === '/me-contacter'}
          onClick={() => setCurrentPage('/me-contacter')}
        >
          Me contacter
        </StyledLink>
      </Wrapper>
    </StyledHeader>
  );
}

export default Header;
