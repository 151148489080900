import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: white;
  width: 100%;
  background-color: rgb(240, 240, 240);

  position: absolute;
  bottom: 0;
`;

const Title = styled.h1`
  font-size: 1em;
  text-align: center;
  color: black;
  margin: 10px;

  @media (max-width: 768px) {
    font-size: 0.5em;
    margin: 5px;
  }
`;

function Footer() {
  return (
    <StyledFooter>
      <Title>Copyright © 2019 Salomé Sitbon. Tous droits réservés.</Title>
    </StyledFooter>
  );
}

export default Footer;
