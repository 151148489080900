import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: white;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: white;
  width: 80%;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0.2em;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-items: center;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const Text = styled.p`
  font-size: 1.5em;
  color: black;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow-wrap: break-word;
  opacity: ${props => (props.hasLoaded ? '1' : '0')};
  transition: opacity 3s ease-in-out 0s;
  font-style: italic;

  max-width: 500px;

  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

const StyledImage = styled.img`
  height: ${window.innerHeight / 1.5}px;
  width: auto;
  opacity: ${props => (props.hasLoaded ? '1' : '0')};
  transition: opacity 3s ease-in-out 0s;
  padding: 30px;

  @media (max-width: 768px) {
    width: ${window.innerWidth / 2}px;
    height: auto;
    padding: 5px;
  }
`;

function Home() {
  const [hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    setHasLoaded(true);
  }, []);

  return (
    <PageWrapper>
      <Wrapper>
        <StyledImage
          hasLoaded={hasLoaded}
          src="/assets/shooting-smile-color.jpg"
          alt="Salomé Sitbon"
        />
        <TextWrapper>
          <Text hasLoaded={hasLoaded}>Je m'appelle Salomé Sitbon.</Text>
          <Text hasLoaded={hasLoaded}>Voici mes expériences</Text>
        </TextWrapper>
      </Wrapper>
      <Wrapper>
        <TextWrapper>
          <Text hasLoaded={hasLoaded}>
            Après un Baccalauréat ES Mention Très Bien, j'ai décidé de
            poursuivre mon cursus scolaire en Classe Préparatoire Littéraire
            option Théatre.
          </Text>
          <Text hasLoaded={hasLoaded}>
            J'y ai découvert un très grand nombre d'auteur et j'ai eu l'occasion
            d'assister à plus de 30 pièces en moins d'un an.
          </Text>
        </TextWrapper>
        <StyledImage
          hasLoaded={hasLoaded}
          src="/assets/dessin_anne_frank.jpg"
          alt="Salomé Sitbon"
        />
      </Wrapper>
      <Wrapper>
        <StyledImage
          hasLoaded={hasLoaded}
          src="/assets/salome-vampire.jpg"
          alt="Salomé Sitbon"
        />
        <TextWrapper>
          <Text hasLoaded={hasLoaded}>
            Afin de mettre en pratique mes compétences de comédienne dans un
            autre contexte j'ai cherché à travailler en parallèle de mes études
            en tant que Game Master dans un Escape Game ... l'Antichambre
          </Text>
          <Text hasLoaded={hasLoaded}>
            Plonger les clients de l'escape game dans une histoire est un jeu
            façinant ! Mais je voulais aller encore plus loin ... Il me fallait
            intégrer un grand Cours de Théatre Parisien.
          </Text>
        </TextWrapper>
      </Wrapper>
      <Wrapper>
        <TextWrapper>
          <Text hasLoaded={hasLoaded}>
            Je suis donc actuellement en deuxième année au Cours Simon. J'ai eu
            la chance l'année dernière d'interprété deux scènes à l'audition de
            fin d'année.
          </Text>
          <Text hasLoaded={hasLoaded}>
            La scène final du "Journal d'Anne Frank", un véritable moment
            d'émotion ...
          </Text>
          <Text hasLoaded={hasLoaded}>
            Une scène de la pièce "De Doux Dingue"
          </Text>
        </TextWrapper>
        <StyledImage
          hasLoaded={hasLoaded}
          src="/assets/cours_simon.jpg"
          alt="Salomé Sitbon"
        />
      </Wrapper>
    </PageWrapper>
  );
}

export default Home;
