import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: white;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: white;
  width: 80%;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0.2em;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-items: center;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const Text = styled.p`
  font-size: 1.5em;
  color: black;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow-wrap: break-word;
  opacity: ${props => (props.hasLoaded ? '1' : '0')};
  transition: opacity 3s ease-in-out 0s;
  font-style: italic;

  max-width: 500px;

  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

const StyledImage = styled.img`
  height: ${window.innerHeight / 1.5}px;
  width: auto;
  opacity: ${props => (props.hasLoaded ? '1' : '0')};
  transition: opacity 3s ease-in-out 0s;
  padding: 30px;

  &:hover {
    opacity: 0.5;
    transition: opacity 0.5s ease-in-out 0s;
  }
  @media (max-width: 768px) {
    width: ${window.innerWidth / 2}px;
    height: auto;
    padding: 5px;
  }
`;

function Story() {
  const [hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    setHasLoaded(true);
  }, []);

  return (
    <PageWrapper>
      <Wrapper>
        <StyledImage
          hasLoaded={hasLoaded}
          src="/assets/pp_salome.jpg"
          alt="Salomé Sitbon"
        />
        <TextWrapper style={{ textAlign: 'left' }}>
          <Text hasLoaded={hasLoaded}>Je m'appelle Salomé Sitbon.</Text>
          <Text hasLoaded={hasLoaded}>Voici mon histoire ...</Text>
        </TextWrapper>
      </Wrapper>
      <Wrapper>
        <TextWrapper>
          <Text hasLoaded={hasLoaded}>
            J'ai grandi dans le 12ème arrondissement de Paris où j'ai fait mes
            premiers pas sur scène
          </Text>
          <Text hasLoaded={hasLoaded}>
            Je fais du théatre depuis que j'ai 8 ans. J'en ai fait à l'école
            puis dans mon centre d'animation.
          </Text>
        </TextWrapper>
        <StyledImage
          hasLoaded={hasLoaded}
          src="/assets/dessin_anne_frank.jpg"
          alt="Salomé Sitbon"
        />
      </Wrapper>
      <Wrapper>
        <StyledImage
          hasLoaded={hasLoaded}
          src="/assets/cours_simon.jpg"
          alt="Salomé Sitbon"
        />
        <TextWrapper>
          <Text hasLoaded={hasLoaded}>
            C'est en étant épanouie sur scène que j'ai compris que le théatre
            était fait pour moi !
          </Text>
          <Text hasLoaded={hasLoaded}>
            Je suis donc allée au conservatoire du 12ème arrondissement puis au
            Cours Simon.
          </Text>
        </TextWrapper>
      </Wrapper>
    </PageWrapper>
  );
}

export default Story;
