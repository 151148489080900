import React from 'react';
import styled from 'styled-components';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: white;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 2em;
  text-align: center;
  color: black;
  margin: 10px;

  @media (max-width: 768px) {
    font-size: 1em;
    margin: 5px;
  }
`;

const Text = styled.p`
  font-size: 1em;
  color: black;
  margin: 10px;
  overflow-wrap: break-word;
  font-style: italic;

  @media (max-width: 768px) {
    font-size: 0.8em;
    margin: 5px;
  }
`;

function Contact() {
  return (
    <PageWrapper>
      <Title>Vous souhaitez me contacter ?</Title>
      <Text>
        Vous avez un projet dont vous voudriez me faire part ? N'hésitez pas à
        prendre contact !
      </Text>
      <Text>
        Demande non sérieuse ou non professionnelle s'abstenir s'il vous plait
      </Text>
      <form
        name="contact"
        method="POST"
        data-netlify="true"
        netlify-honeypot="bot-field"
        style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}
      >
        <input type="hidden" name="form-name" value="contact" />
        <p
          class="hidden"
          style={{ visibility: 'hidden', height: '5px', margin: 0 }}
        >
          <label>
            Don’t fill this out if you're human: <input name="bot-field" />
          </label>
        </p>
        <p>
          <label style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Text>Votre Nom: </Text>
            <input type="text" name="name" />
          </label>
        </p>
        <p>
          <label style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Text>Votre Email:</Text>
            <input type="email" name="email" />
          </label>
        </p>
        <p>
          <label style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Text>Votre Message: </Text>
            <textarea name="message" rows="5"></textarea>
          </label>
        </p>
        <p style={{ alignSelf: 'flex-end' }}>
          <button type="submit">Envoyer</button>
        </p>
      </form>
    </PageWrapper>
  );
}

export default Contact;
