import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Story from './Pages/Story';
import Experience from './Pages/Experience';
import Contact from './Pages/Contact';
import Header from './Components/Header';
import Footer from './Components/Footer';

function App() {
  return (
    <Router>
      <div
        style={{
          position: 'relative',
          minHeight: '100%',
          margin: '0 auto'
        }}
      >
        <Header />
        <Switch>
          <Route path="/me-contacter">
            <Contact />
          </Route>
          <Route path="/mon-histoire">
            <Story />
          </Route>
          <Route path="/mon-expérience">
            <Experience />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
