import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: white;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  margin: 1em;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: white;
  width: 80%;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    margin: 0.2em;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const Text = styled.h1`
  font-size: 2em;
  color: black;
  margin: 10px;
  overflow-wrap: break-word;
  opacity: ${props => (props.hasLoaded ? '1' : '0')};
  transition: opacity 3s ease-in-out 0s;
  font-style: italic;
  max-width: 500px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 1em;
    text-align: center;
  }
`;

const StyledImage = styled.img`
  height: ${window.innerHeight / 1.5}px;
  width: auto;
  opacity: ${props => (props.hasLoaded ? '1' : '0')};
  transition: opacity 3s ease-in-out 0s;

  &:hover {
    opacity: 0.5;
    transition: opacity 0.5s ease-in-out 0s;
  }

  @media (max-width: 768px) {
    width: ${window.innerWidth / 2}px;
    height: auto;
  }
`;

function Home() {
  const [hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    setHasLoaded(true);
  }, []);

  return (
    <PageWrapper>
      <Wrapper>
        <StyledImage
          hasLoaded={hasLoaded}
          src="/assets/shooting-salome.jpg"
          alt="Salomé Sitbon"
        />
        <TextWrapper>
          <Text hasLoaded={hasLoaded}>Je m'appelle Salomé Sitbon.</Text>
          <Text hasLoaded={hasLoaded}>
            Je suis comédienne en cours de professionnalisation au Cours Simon à
            Paris
          </Text>
        </TextWrapper>
      </Wrapper>
    </PageWrapper>
  );
}

export default Home;
